<template>
  <div />
  <!-- <tr>
    <td
      @click="
        (!video.isProtectedVideo || !labeler) && $emit('handlePlay', video)
      "
    >
      <img
        :src="thumbnailUrl(video)"
        v-if="video.thumbnail_path"
        alt="thumbnail.jpg"
        width="140"
        height="124"
      />
      <video-player
        v-else
        :videoUrl="getvideoURL"
        :videoName="video.fileName"
        :index="index"
        @updateVideoUrl="updateVideoUrl"
      ></video-player>
    </td>
    <td>
      <i
        class="bi bi-cloud-arrow-down-fill"
        v-bind:style="{ color: status }"
        @click="downloadVideo"
        @mouseover="changeHoverColor('active')"
        @mouseleave="changeHoverColor('inactive')"

      ></i>
    </td>
    <td>{{ video.fileName }}</td>
    <td>{{ video.resolution }}</td>
    <td>{{ video.duration }}</td>
    <td>{{ video.fps }}</td>
    <td>
      <circle-progress
        v-if="video.labelled >= 1"
        class="mx-auto"
        :percent="video.labelled > 100 ? 100 : video.labelled"
        :show-percent="true"
        :size="40"
        :border-width="4"
        :border-bg-width="4"
      ></circle-progress>
      <div v-else class="text-center">
        {{ video.labelled.toFixed(1) }}
      </div>
    </td>

    <td :key="video.can_label">
      <button
        :id="index === 0 ? 'v-step-22' : ''"
        class="btn btn-primary btn-sm"
        @click="handleAnnotate"
        :disabled="isAnnotationDisabled"
      >
        Annotate
      </button>
    </td>
    <td>
      <button
        :id="index === 0 ? 'trim-button' : ''"
        class="btn btn-primary btn-sm"
        @click="$emit('trimVideo', video)"
        :disabled="isTrimmingDisabled"
      >
        Trim
      </button>
    </td>
  </tr>
    <td>
      <button
          :id="index === 0 ? 'download-video-button' : ''"
          class="btn btn-primary btn-sm"
          @click="downloadVideo"
          :disabled="!getvideoURL || video.availableLocally"
      >
        Download
      </button>
    </td>
  </tr>
  -->
</template>
<script>
import VideoPlayer from '../../../user-panel/pages/trace/VideoPlayer.vue';
import httpClient from '../../../../service/httpClient';
import CircleProgress from 'vue3-circle-progress';
import { useToast } from 'vue-toastification';

export default {
  emits: ['handlePlay', 'updateVideoDetails', 'annotateVideo', 'trimVideo'],
  props: ['taskId', 'video', 'index', 'taskName', 'organization', 'showAnnotationModal'],
  components: { VideoPlayer, CircleProgress },
  data() {
    return {
      polling: null,
      pollingVideo: null,

      pollingTime: 1000,
      pollingTimeVideo: 1000,

      isPolled: false,
      isPolledVideo: false,

      status: 'grey',
      tempURL: null,
      tempisavailablelocally: null,
      videoLocalURL: '',
    };
  },
  created() {
    window.addEventListener('beforeunload', this.resetPolling);
    window.addEventListener('beforeunload', this.resetPollingVideo);
  },
  inject: ['toast'],
  mounted() {
    // if (this.video.ant_media_id !== ""){
    //   this.videoLocalURL =
    //       'https://dev.retrocausal.tech:5443/LiveApp/streams/' +
    //       String(this.video.ant_media_id) +
    //       '.mp4';
    // }
    this.startPolling();
  },

  computed: {
    isAnnotationDisabled() {
      return (
        !this.taskId || !this.video.can_label
        // !this.taskId || this.video.isProtectedVideo || !this.video.can_label
      );
    },
    isTrimmingDisabled() {
      return !this.taskId;
      // return !this.taskId || this.video.isProtectedVideo;
    },
    getvideoURL() {
      return this.video.localURL || this.video.fileURL;
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  watch: {
    video: function(value) {
      if (!this.video.availableLocally) {
        this.status = 'grey';
      }
    },

    showAnnotationModal(showAnnotationModal) {
      if (showAnnotationModal){
        clearInterval(this.pollingVideo)
        console.log("Polling stopped")
      }
      else{
        this.startPolling();
        console.log("Polling started")
      }
    },
  },
  methods: {
    thumbnailUrl(video) {
      return (
        'https://retrocausal-video-thumbnails.s3.amazonaws.com/' +
        video.thumbnail_path +
        '512.jpg'
      );
    },
    updateVideoUrl(videoName, url) {
      this.$emit('updateVideoDetails', videoName, {
        fileURL: url,
        localURL: this.video.localURL,
      });
    },
    changeHoverColor(status) {
      if (!this.video.availableLocally) {
        if (status === 'active') {
          this.status = 'lightskyblue';
        } else {
          this.status = 'grey';
        }
      }
    },
    startPolling() {
      // this.polling = setInterval(this.getVideoDetails, this.pollingTime);
      this.pollingVideo = setInterval(
        this.getVideoLocally,
        this.pollingTimeVideo
      );
    },
    async downloadVideo() {
      if (this.video.availableLocally) return;
      console.log('yep');
      const baseURL =
        '/downloadVideo/' +
        String(this.organization) +
        '/' +
        this.taskId +
        '/' +
        this.taskName +
        '/' +
        String(this.video.fileName);
      const res = await httpClient.get(
        baseURL,
        false,
        true,
        false,
        'successful',
        'failed',
        'http://localhost:5000/'
      );
      if (res === 403) {
        this.toast.error('Error in downloading video. Try again later.');
        return;
      }
      console.log('done');
      this.toast.info(String(this.video.fileName) + ' download has started!');
    },

    async getVideoLocally() {
      if (this.isPolledVideo) return;
      this.isPolledVideo = true;
      let res = await this.isVideoOnServer(this.video);
      if (res === 'error') {
        // "error" means any error exclusive of 403
        console.log('not available on server');
        this.video.availableLocally = false;
        // this.status = 'grey';
        this.$emit('updateVideoDetails', this.video.fileName, {
          status: 'grey',
        });
        await this.getVideoDetails();
      } else {
        console.log('available on server');
        // this.status = 'lightgreen';
        this.$emit('updateVideoDetails', this.video.fileName, {
          status: 'lightgreen', availableLocally: true, localURL: this.videoLocalURL
        });
        // this.updateVideoURLToLocal();
      }
      this.isPolledVideo = false;
    },

    async isVideoOnServer(video) {
      if (video.ant_media_id.length > 0){
        // set videoLocalURL
        this.videoLocalURL = this.video.fileURL
        return 'found'
      }
      else{
        return 'error'
      }
    },

    updateVideoURLToLocal: function() {
      if (this.video.localURL !== this.videoLocalURL) {
        this.video.localURL = this.videoLocalURL;
        this.status = 'lightgreen';
        this.$emit('updateVideoDetails', this.video.fileName, this.video);
      }
    },
    handleAnnotate() {
      // this.resetPolling();
      // this.isPolled = false;
      this.$emit('annotateVideo', this.video);
    },

    async getVideoDetails() {
      if (this.isPolled) return;
      this.isPolled = true;

      this.tempURL = this.video.localURL;
      this.tempisavailablelocally = this.video.availableLocally;
      //
      const res = await httpClient.getData(
        'organization/task_record/' + this.video.id + '/',
        false
      );
      if (res === 'error') {
        this.resetPolling();
      }
      res.availableLocally = this.tempisavailablelocally;
      if (this.tempisavailablelocally) {
        // res.localURL = this.tempURL;
        if (this.video.localURL !== this.videoLocalURL) {
          res.localURL = res.tempURL;
        }
      } else {
        res.localURL = '';
        if (!this.status === 'lightskyblue') {
          this.status = 'grey';
        }
      }
      this.isPolled = false;
      if (JSON.stringify(res) !== JSON.stringify(this.video)) {
        this.$emit('updateVideoDetails', this.video.fileName, res);
      }
    },

    resetPolling() {
      clearInterval(this.polling);
    },
    resetPollingVideo() {
      clearInterval(this.pollingVideo);
    },
  },
  beforeUnmount() {
    this.resetPolling();
    this.resetPollingVideo();
    // this.resetPollingProgress();
  },
};
</script>
<style scoped>
td {
  color: black;
}

.thumbnail {
  width: 100px;
}
</style>
