<template>
  <!-- Annotation Confirm Modal State -->
  <a-modal v-model:visible="showAnnotationConfirmModal" title="Confirmation">
    <p>
      Are you sure you want to exit labeling? Please select one of the options
      below.
    </p>
    <template #footer>
      <div class="d-flex w-100">
        <span class="ml-auto" />
        <a-button @click="handleAnnotationClose"
          >Continue without saving</a-button
        >
        <a-button type="primary" @click="emitter.emit('save-label')"
          >Save progress</a-button
        >
      </div>
    </template>
  </a-modal>

  <!-- Annotation Modal -->
  <a-modal
    :visible="showAnnotationModal"
    :title="null"
    width="100%"
    wrap-class-name="full-modal"
    :bodyStyle="{ padding: '1em', display: 'flex', flexDirection: 'column' }"
    destroyOnClose
  >
    <a-spin class="m-auto" size="large" v-if="loading" />
    <annotation-component
      :loading="loading"
      ref="annotationComponent"
      :video="selectedVideo"
      :key="selectedVideo"
      :taskId="taskId"
      :prevMetaData="prevMetaData"
      :organization="organization"
      :updateTaskRecord="updateTaskRecord"
      @stopLoading="loading = false"
      @updateLoading="(val) => (loading = val)"
      @onSave="handleLabelling"
      @closeModal="showAnnotationConfirm"
    ></annotation-component>
    <template #closeIcon>
      <close-circle-outlined @click="showAnnotationConfirm" />
    </template>
    <template #footer>
      <div class="d-flex w-100">
        <span class="ml-auto" />
        <a-button
          :disabled="isSavingAnnoation"
          v-if="!loading"
          @click="showAnnotationConfirm"
          >Cancel</a-button
        >
        <a-button
          v-if="!loading"
          :disabled="!isSubstepsChanged"
          type="primary"
          :loading="isSavingAnnoation"
          @click="emitter.emit('save-label')"
          >Save</a-button
        >
      </div>
    </template>
  </a-modal>

  <!-- Trimming Modal -->
  <a-modal
    v-model:visible="showTrimModal"
    :title="null"
    width="100%"
    wrap-class-name="full-modal"
    :bodyStyle="{ padding: '1em', display: 'flex', flexDirection: 'column' }"
    destroyOnClose
    @cancel="handleTrimModalClose"
  >
    <a-spin class="m-auto" size="large" v-if="loading" />
    <trimming-component
      ref="trimming"
      :loading="loading"
      :video="videoToTrim"
      :key="videoToTrim"
      :taskId="taskId"
      :taskName="taskName"
      @stopLoading="loading = false"
      @updateTrimProgress="(progress) => (trimProgress = progress)"
      @updateTrimLoading="(isLoading) => (isTrimming = isLoading)"
      @closeModal="finishTrimming"
    ></trimming-component>

    <template #footer>
      <a-row class="w-100 d-flex align-items-center">
        <a-col span="21"
          ><a-progress :percent="trimProgress.toFixed(1)" v-if="isTrimming"
        /></a-col>
        <a-col span="2" class="ml-auto"
          ><a-button
            v-if="!loading"
            type="primary"
            :loading="isTrimming"
            @click="emitter.emit('trim-video')"
            >Trim Video</a-button
          ></a-col
        >
      </a-row>
    </template>
  </a-modal>

  <!-- Video Player Modal -->
  <a-modal
    centered
    v-model:visible="showVideoModal"
    :title="videoToPlay?.fileName"
  >
    <template #footer>
      <a-descriptions title="Details" class="text-start">
        <a-descriptions-item label="Name" span="3">
          <a-tag color="blue">
            {{ videoToPlay?.fileName }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Resolution">
          <a-tag color="blue">
            {{ videoToPlay?.resolution }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Duration" span="2">
          <a-tag color="blue">
            {{ videoToPlay?.duration }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="FPS">
          <a-tag color="blue">
            {{ videoToPlay?.fps }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Labelled">
          <a-tag color="blue"> {{ videoToPlay?.labelled.toFixed(1) }}% </a-tag>
        </a-descriptions-item>
      </a-descriptions>
    </template>

    <div
      class="w-100 d-flex"
      style="height:350px !important;"
      v-if="isfetchingVideoUrl"
    >
      <a-spin class="m-auto" size="large" />
    </div>
    <video v-else class="w-100" controls :src="videoToPlay?.fileURL"></video>
  </a-modal>

  <a-row class="p-4">
    <a-col span="24" v-if="isDownloading">
      <a-list
        size="small"
        bordered
        :data-source="currentDownload"
        v-if="isDownloading"
        class="bg-white mb-2"
      >
        <template #header>
          <div>Downloading</div>
        </template>
        <template #renderItem="{ item }">
          <a-list-item>
            <span>
              {{ item.taskVideo }}
            </span>
            <a-progress
              type="dashboard"
              :percent="item.downloadProgress"
              :width="40"
            />
          </a-list-item>
        </template>
      </a-list>
    </a-col>

    <a-col span="24">
      <video-list
        :taskId="taskId"
        :taskName="taskName"
        :organization="organization"
        :video_list="video_list"
        :videosCount="videosCount"
        :sortBy="sortBy"
        :sortOrder="sortOrder"
        :loading="loadingVideos"
        :updateTaskRecord="updateTaskRecord"
        @fetchVideosList="fetchVideos"
        @handlePlay="handlePlay"
        @updateVideo="updateVideo"
        @annotateVideo="handleAnnotate"
        @trimVideo="handleTrim"
        @deleteVideo="handleDeleteVideo"
        @sortList="handleSortList"
        @pageChange="handlePageChange"
      ></video-list>
    </a-col>
  </a-row>

  <video-record
    ref="videoRecord"
    v-for="(video, index) in video_list"
    :key="video.id"
    :video="video"
    :index="index"
    :taskId="taskId"
    :showAnnotationModal="showAnnotationModal"
    @handlePlay="handlePlay"
    @updateVideoDetails="updateVideo"
    @annotateVideo="handleAnnotate"
    @trimVideo="handleTrim"
  ></video-record>
</template>
<script>
import httpClient from '../../../../service/httpClient';
import { POSITION } from 'vue-toastification';
import VideoList from '../../../user-panel/pages/import/VideoListV2.vue';
import VideoRecord from './VideoRecord.vue';
import ModalComponent from '../../../shared/Components/FullPageModal.vue';
import SpinnerComponent from '../../../shared/Components/Spinner.vue';
import AnnotationComponent from '../../../user-panel/pages/annotation/Annotation.vue';
import TrimmingComponent from '../../../user-panel/pages/import/Trimming/Trimming.vue';
import VideoModal from '../../../user-panel/pages/import/VideoModal.vue';
import DialogComponent from '../../../shared/Components/Dialog.vue';
import getTimeDuration from '../../../shared/Helpers/getDuration';
import { events } from '../../../../config/event-config';
import { mapActions, mapGetters } from 'vuex';
import Pagination from 'v-pagination-3';
import { CloseCircleOutlined } from '@ant-design/icons-vue';
import getStepsList from '../../../shared/Helpers/getStepsList';
import axios from 'axios';
import { roles } from '@/config/roles-config';

export default {
  props: ['organization', 'taskId', 'taskName'],

  components: {
    VideoList,
    ModalComponent,
    SpinnerComponent,
    TrimmingComponent,
    AnnotationComponent,
    DialogComponent,
    VideoModal,
    Pagination,
    VideoRecord,
    CloseCircleOutlined,
  },
  inject: ['toast'],

  data() {
    return {
      headers: [
        'Thumbnail',
        '',
        'Name',
        'Resolution',
        'Duration',
        'FPS',
        'labelled',
        'Annotated',
        'Trimming',
        '',
      ],
      video_list: [],
      videosCount: 0,
      page: 1,
      pageSize: 10,
      sortBy: '',
      sortOrder: 'asc',
      videoToPlay: null,
      showVideoModal: false,
      isfetchingVideoUrl: false,
      selectedVideo: null,
      loading: true,
      loadingVideos: false,
      showAnnotationModal: false,
      showAnnotationConfirmModal: false,
      showTrimModal: false,
      isTrimming: false,
      trimProgress: 0,
      hasFetchCalled: false,
      isConfirm: false,
      videoToTrim: null,
      listPolling: null,
      updatePolling: null,
      isPolled: false,
      listPollingTime: 1000,
      updatePollingTime: 1000,
      warningTimeout: null,
      warningTime: 30,
      modalClosingTimeout: null,
      modalClosingTime: 45,
      pollingProgress: null,
      isPolledProgress: false,
      pollingTimeProgress: 1000,
      currentDownload: [],
      isDownloading: false,
      isJustAnnotated: false,
    };
  },

  created() {
    console.log('this', this);
    window.addEventListener('beforeunload', this.resetPollings);
    console.log('Organization: ', this.organization);
  },

  async mounted() {
    if (this.taskId) {
      this.loadingVideos = true;
      this.fetchTaskDetails(this.taskId);
      await this.fetchVideos();
      this.loadingVideos = false;
      // this.pollingProgress = setInterval(
      //   this.checkProgress,
      //   this.pollingTimeProgress
      // );

      // this.listPolling = setInterval(this.getVideos, this.listPollingTime);
    }
  },

  computed: {
    ...mapGetters([
      'isSavingAnnoation',
      'isSubstepsChanged',
    ]),
  },

  watch: {
    isConfirm: function(value) {
      if (value === null) return;
      if (value) {
        this.$refs.trim.toggleModal();
      }
      this.isConfirm = null;
      this.$refs.dialog.toggleModal();
    },

    video_list(videos) {
      if (this.hasFetchCalled) return;
      const videosWithoutThumbnail = videos.filter((v) => !v.thumbnail_path);
      this.fetchAndUpdateThumbnails(videosWithoutThumbnail);
    },
  },

  methods: {
    ...mapActions([
      'changeRoute',
      'setPrevMetaData',
      'fetchTaskDetails',
    ]),

    async fetchAndUpdateThumbnails(videosWithoutThumbnail) {
      this.hasFetchCalled = true;
      for await (const video of videosWithoutThumbnail) {
        const url = await this.getVideoUrl(video);
        const { bucket, filePath: path } = this.getVideoS3Details(video);
        const thumbnail_path = await this.getThumbnailPath(url, bucket, path);
        await this.updateVideoRecord(thumbnail_path, video);
      }
      this.hasFetchCalled = false;
    },

    async getVideoUrl(video) {
      const { bucket, filePath } = this.getVideoS3Details(video);
      return await this.fetchPresignedUrl(bucket, filePath);
    },

    getVideoS3Details(video) {
      const { fileName } = video;
      const bucket = `${this.organization}-training`;
      const filePath = `${this.taskName}/Videos/${fileName}`;
      return { bucket, filePath };
    },

    async fetchPresignedUrl(bucket_name, object_path) {
      const payload = {
        bucket_name,
        object_path,
      };
      return new Promise(async (resolve, _) => {
        const res = await httpClient.post(
          'generic/generate_new_url/',
          payload,
          false,
          false,
          false
        );
        resolve(res.presigned_url || res);
      });
    },

    async getThumbnailPath(file_url, bucket_name, file_path) {
      const payload = {
        file_url,
        bucket_name,
        file_path,
      };
      return await httpClient.post(
        'generic/create_thumbnail/',
        payload,
        false,
        false,
        false
      );
    },

    async fetchVideos(page = this.page) {
      // this.loadingVideos = true;
      let url = 'organization/task/upload/' + this.taskId + '/';

      url = page > 1 ? `${url}?page=${page}` : url;
      const res = await httpClient.getData(url, false);
      // this.loadingVideos = false;

      return new Promise((resolve, reject) => {
        if (res === 'error') {
          this.toast.error('Error Occured while loading video please reload!');
          this.resetListPolling();
          return reject();
        }
        if (res === 403) {
          this.resetListPolling();
          this.toast.info(
            `You have been unassigned from ${this.taskName} task!`
          );
          setTimeout(this.redirectToTaskScreen, 3000);
        }
        this.video_list = res.results;
        this.videosCount = res.count;
        return resolve();
      });
    },

    getKey(video) {
      return JSON.stringify(video);
    },

    handlePageChange(page) {
      this.page = page;
      this.fetchVideos(page);
    },

    async updateVideoRecord(updatedData, videoToUpdate) {
      this.updateVideo(videoToUpdate.fileName, updatedData);
      await httpClient.patch(
        'organization/task_record/',
        videoToUpdate.id + '/',
        updatedData,
        false,
        false,
        false
      );
    },

    updateVideo(videoName, videoObj) {
      const temp = [...this.video_list];
      const index = temp.findIndex((v) => v.fileName === videoName);
      if (temp[index]) {
        temp[index] = {
          ...temp[index],
          ...videoObj,
        };
      }
      this.video_list = temp;
    },

    async handlePlay(video) {
      if (!video?.fileName) return;
      this.showVideoModal = true;
      if (!video.fileURL) {
        this.isfetchingVideoUrl = true;
        const presignedUrl = await this.getVideoUrl(video);
        this.isfetchingVideoUrl = false;
        if (presignedUrl === 'error') {
          this.toast.error('Error occured while fetching url!');
          return;
        }
        video = { ...video, fileURL: presignedUrl };
      }
      this.videoToPlay = { ...video };
    },

    handleAnnotate(video) {
      this.selectedVideo = { ...video };
      this.getPrevMetaData();
    },

    getPreMetaDataUrl() {
      const url = `organization/task/upload/retrieve_metadata?org=${
        this.organization
      }&task_name=${
        this.taskName
      }&video_name=${this.selectedVideo?.fileName.replaceAll(
        '+',
        '%2B'
      )}&task_id=${this.taskId}`;
      return url;
    },

    async getPrevMetaData() {
      if (!this.selectedVideo) return;
      const url = this.getPreMetaDataUrl();

      let response = await httpClient.getData(url);
      if (response === 403 && this.isJustAnnotated)
        this.toast.info('Please wait 10 seconds to label the video again');
      if (response === 403 && !this.isJustAnnotated)
        this.toast.info('Someone else is labeling!');
      if (['error', 403].includes(response)) return;
      this.startAnnotation();
      if (response.error === 'NoSuchKey') {
        // this.prevMetaData = null;
        this.setPrevMetaData(null);
      } else {
        this.setMetaData(response);
      }
    },

    startAnnotation() {
      this.showAnnotationModal = true;
      // this.subsscribeEvents();
      this.resetListPolling();
      // this.updatePolling = setInterval(
      //   this.updateTaskRecord,
      //   this.listPollingTime
      // );
    },

    setMetaData(response) {
      if (typeof response === 'string') {
        response = response.replaceAll('Infinity', Number.MAX_SAFE_INTEGER);
        const parsedResponse = JSON.parse(response);
        // this.prevMetaData = parsedResponse;
        this.setPrevMetaData(parsedResponse);
      } else {
        this.setPrevMetaData(response);
        // this.prevMetaData = response;
      }
    },

    updateTaskRecord(updatedData, videoToUpdate) {
      return new Promise(async (resolve) => {
        this.loading = true;
        this.updateVideo(videoToUpdate.fileName, updatedData);
        const res = await httpClient.patch(
          'organization/task_record/',
          videoToUpdate.id + '/',
          updatedData,
          false,
          false,
          false
        );
        resolve();
        if (res === 'error')
          this.toast.error('Error occured while updating record!');
      });
    },

    handleTrim(video) {
      this.videoToTrim = { ...video };
      this.showTrimModal = true;
    },

    handleSortList(tempHeader) {
      let header = tempHeader;
      if (tempHeader === 'name') {
        header = 'fileName';
      }
      if (tempHeader === this.sortBy && this.sortOrder === 'asc') {
        this.sortOrder = 'des';
      } else {
        console.log('sortby', header);
        this.sortBy = header === 'fileName' ? 'name' : header;
        this.sortOrder = 'asc';
      }

      let tempList = this.video_list.sort((a, b) =>
        this.getSortOrder(a, b, header, this.sortOrder)
      );

      this.video_list = tempList;
    },

    getSortOrder(a, b, field, sortOrder) {
      let orders = {};
      if (field === 'duration') {
        orders = {
          asc: () => getTimeDuration(a[field]) - getTimeDuration(b[field]),
          des: () => getTimeDuration(b[field]) - getTimeDuration(a[field]),
        };
      } else if (field === 'fileName') {
        orders = {
          asc: () => -1,
          des: () => -1,
        };
      } else {
        orders = {
          asc: () => a[field] - b[field],
          des: () => b[field] - a[field],
        };
      }
      return orders[sortOrder]();
    },

    handleLabelling({ data, isEmptyJson }) {
      this.showAnnotationModal = false;
      if (isEmptyJson) return;
      const { id, labelled } = data;
      const temp = [...this.video_list];
      const index = temp.findIndex((v) => v.id === id);
      temp[index].labelled = labelled;
      this.video_list = temp;
      this.handleAnnotationClose();
    },

    showAnnotationConfirm() {
      if (this.isSubstepsChanged) this.showAnnotationConfirmModal = true;
      else this.handleAnnotationClose();
    },

    handleAnnotationClose() {
      this.showAnnotationConfirmModal = false;
      // code snippet - start:  adding new code snippet to close frame_producer after labeling
      const msgDict = {
        typeMsg: 'closeProducer',
      };

      try {
        this.webSocketConnection.send(JSON.stringify(msgDict));
      } catch (err) {
        console.log(err);
      }
      // code snippet - end
      this.loading = true;
      this.resetUpdatePolling();
      // this.destroyEvents();
      this.toast.info(
        `${this.selectedVideo.fileName} will be available in 10 second(s)!`,
        { timeout: 3000, position: POSITION.TOP_LEFT }
      );
      this.showAnnotationModal = false;
      this.isJustAnnotated = true;
      const self = this;
      setTimeout(() => {
        self.isJustAnnotated = false;
      }, 10000);
    },

    finishTrimming() {
      this.showTrimModal = false;
      this.fetchVideos();
      this.trimProgress = 0;
      this.isTrimming = false;
    },

    redirectToTaskScreen() {
      const name = 'Tasks Requested';
      this.$router.replace({
        name,
      });
      this.changeRoute(name);
    },

    resetPollings() {
      this.resetListPolling();
      this.resetUpdatePolling();
      this.resetPollingProgress();
      this.webSocketConnection.close();
      // this.destroyEvents();
    },

    resetListPolling() {
      clearInterval(this.listPolling);
    },

    resetUpdatePolling() {
      console.log('clear update polling');
      clearInterval(this.updatePolling);
    },

    subsscribeEvents() {
      console.log('subscribe events');
      events.forEach((event) => {
        window.addEventListener(event, this.resetTimer);
      });
      this.setTimer();
    },

    destroyEvents() {
      events.forEach((event) => {
        window.removeEventListener(event, this.resetTimer);
      });
      clearTimeout(this.warningTimeout);
      clearTimeout(this.modalClosingTimeout);
      // this.resetTimer();
    },

    setTimer() {
      this.warningTimeout = setTimeout(
        this.displayWarningMessage,
        this.warningTime * 1000
      );
      this.modalClosingTimeout = setTimeout(
        this.handleAnnotationClose,
        this.modalClosingTime * 1000
      );
    },

    resetTimer() {
      clearTimeout(this.warningTimeout);
      clearTimeout(this.modalClosingTimeout);
      this.setTimer();
    },

    resetPollingProgress() {
      clearInterval(this.pollingProgress);
    },

    displayWarningMessage() {
      this.toast.info('Are you still with us?');
    },
  },

  beforeUnmount() {
    this.resetPollings();
  },
};
</script>
<style scoped>
td {
  color: black;
}

.sort-icon {
  font-size: 12px;
  margin-right: 3px;
}

.video-play {
  width: 100px;
  height: 60px;
}

.table-header-sticky {
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
}
.cursor-p {
  cursor: pointer;
}

.table-container {
  height: 75vh;
}

.protected-text {
  padding: 0;
  margin: 0 0 0 1.5em !important;
  color: red;
  font-size: 0.875em;
}
</style>
