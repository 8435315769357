<template>
  <a-table
    :data-source="datList"
    :columns="columns"
    :loading="loading"
    :pagination="pagination"
    :scroll="{
      scrollToFirstRowOnChange: true,
      x: true,
    }"
    @change="handleTableChange"
  >
    <template #headerCell="{ column }">
      <template v-if="column.key === 'name'">
        <span style="color: #1890ff">Name</span>
      </template>
    </template>
    <template
      #customFilterDropdown="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }"
    >
      <div style="padding: 8px">
        <a-input
          ref="searchInput"
          :placeholder="`Search ${column.dataIndex}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
          type="primary"
          size="small"
          style="width: 90px; margin-right: 8px; display: inline-flex;"
          @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          <template #icon><SearchOutlined /></template>
          Search
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="handleReset(clearFilters)"
        >
          Reset
        </a-button>
      </div>
    </template>
    <template #customFilterIcon="{ filtered }">
      <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
    </template>
    <template #bodyCell="{ text, column }">
      <span v-if="column.dataIndex === 'thumbnail_path'">
        <img
          :src="thumbnailUrl(text)"
          alt="thumbnail.jpg"
          width="140"
          height="124"
        />
      </span>
      <span v-if="column.dataIndex === 'labelled'">
        <a-progress
          type="circle"
          :percent="text.toFixed(1)"
          status="normal"
          :width="50"
        />
      </span>
      <span v-if="column.dataIndex === 'annotation'">
        <a-button type="primary" >Annotate</a-button>
      </span>
      <span v-if="column.dataIndex === 'trimming'">
        <a-button type="primary">Trim</a-button>
      </span>
      <span v-if="column.dataIndex === 'isProtectedVideo'">
        <a-switch :checked="text" size="small" />
      </span>
      <span v-if="column.dataIndex === 'created_at'">
        {{ formatDate(text) }}
      </span>
      <span v-if="column.dataIndex === 'actions'">
        <a-button type="primary" class="d-inline-flex"
          ><download-outlined
        /></a-button>
        <a-button type="danger" class="d-inline-flex mt-2">
          <delete-outlined
        /></a-button>
      </span>
    </template>
  </a-table>
</template>
<script>
import {
  SearchOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons-vue';
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from 'vue';
import httpClient from '../../../service/httpClient';
import dateHelper from '../Helpers/dateHelper';
const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
  },
  {
    key: '2',
    name: 'Joe Black',
    age: 42,
    address: 'London No. 1 Lake Park',
  },
  {
    key: '3',
    name: 'Jim Green',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
  },
  {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
  },
];
export default defineComponent({
  components: {
    SearchOutlined,
    DownloadOutlined,
    DeleteOutlined,
  },

  setup() {
    onMounted(onMount);

    const state = reactive({
      searchText: '',
      searchedColumn: '',
      loading: false,
      datList: [],
      count: 0,
      pageSize: 10,
      current: 1,
    });

    const searchInput = ref();

    const columns = [
      {
        title: 'Thumbnail',
        dataIndex: 'thumbnail_path',
        key: 'thumbnail_path',
      },
      {
        title: 'Filename',
        dataIndex: 'fileName',
        key: 'fileName',
        customFilterDropdown: true,
        onFilter: (value, record) => {
          console.log({ value, record });
          return record.fileName
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        },
      },
      {
        title: 'Resolution',
        dataIndex: 'resolution',
        key: 'resolution',
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
      },
      {
        title: 'Upload Time',
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: 'FPS',
        dataIndex: 'fps',
        key: 'fps',
        customFilterDropdown: true,
        onFilter: (value, record) =>
          record.fps
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              searchInput.value.focus();
            }, 100);
          }
        },
      },
      {
        title: 'Labeled',
        dataIndex: 'labelled',
        key: 'labelled',
      },
      {
        title: 'Annotation',
        dataIndex: 'annotation',
        key: 'annotation',
      },
      {
        title: 'Trimming',
        dataIndex: 'trimming',
        key: 'trimming',
      },
      {
        title: 'Protected',
        dataIndex: 'isProtectedVideo',
        key: 'isProtectedVideo',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
      },
    ];

    function onMount() {
      getList();
    }

    function getUrl(page) {
      let url = 'organization/task/upload/196/?';
      // return url;
      // const orderMapping =
      //   {
      //     asc: '',
      //     des: '-',
      //   }[this.sortOrder] || '';

      // const sortBy = this.sortBy === 'uploadtime' ? 'created_at' : this.sortBy;
      url = page > 1 ? `${url}page=${page}` : url;
      // const orderBy = `${orderMapping}${sortBy}`;
      // url += `&ordering=${orderBy}`;
      return url;
    }

    async function getList(page = 1) {
      state.loading = true;
      let url = getUrl(page);
      const res = await httpClient.getData(url, false);
      state.datList = res.results;
      state.count = res.count;
      state.loading = false;
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilters) => {
      clearFilters();
      state.searchText = '';
    };

    const pagination = computed(() => ({
      total: state.count,
      current: state.current,
      pageSize: state.pageSize,
      showSizeChanger: false,
    }));

    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', { pag, filters, sorter });
      const { current } = pag;
      state.current = current;
      getList(current);
    };

    function thumbnailUrl(thumbnail_path) {
      console.log('thumbnail_path', thumbnail_path);
      return (
        'https://retrocausal-video-thumbnails.s3.amazonaws.com/' +
        thumbnail_path +
        '512.jpg'
      );
    }

    return {
      data,
      columns,
      handleSearch,
      pagination,
      handleReset,
      searchInput,
      handleTableChange,
      thumbnailUrl,
      ...toRefs(state),
      formatDate: dateHelper.formatDate,
    };
  },
});
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
