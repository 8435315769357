<template>
  <video
    :src="videoUrl"
    :controls="control"
    class="video-play my-auto"
    :id="videoUrl"
    preload="auto"
    @loadstart="loadStart"
    @canplay="canplay"
    @error="onError"
  />
</template>

<script>
import isLinkExpired from '../../../shared/Helpers/isLinkExpired';
import httpClient from '../../../../service/httpClient';
import loadingGif from '../../../../assets/img/gifs/loading.gif';
import noVideo from '../../../../assets/img/no-video.png';

export default {
  props: ['videoName', 'videoUrl', 'control', 'index'],
  emits: ['updateVideoUrl'],
  data() {
    return {
      loading: true,
      loadingGif,
      timeout: null,
      videoElement: null,
      loadingTimeInSec: 60,
      log: console.log,
      flag: false,
    };
  },

  mounted() {
    this.checkIfVideoExpired();
  },

  updated() {
    this.checkIfVideoExpired();
  },

  watch: {
    videoUrl(value) {
      console.log('render watch video url', this.index);
      this.url = value;
    },
  },

  methods: {
    updateLink() {
      if (this.flag) return;
      httpClient
        .put(
          'organization/regenerate_url',
          '',
          {
            expired_url: this.videoUrl,
          },
          true,
          false,
          false
        )
        .then((response) => {
          this.flag = true;
          this.$emit('updateVideoUrl', this.videoName, response);
          // this.loadStart();
        });
    },
    loadStart() {
      // this.start = performance.now();
      if (this.index === 0) {
        console.log('load Start', this.index);
      }
      this.videoElement?.setAttribute('poster', loadingGif);
      // this.timeout = setTimeout(
      //   this.hanldeVideoError,
      //   this.loadingTimeInSec * 1000
      // );
    },
    canplay() {
      // console.log("canplay: ", performance.now() - this.start);
      console.log('can Play', this.index);
      // clearTimeout(this.timeout);

      this.videoElement?.removeAttribute('poster');
    },
    hanldeVideoError() {
      this.videoElement.setAttribute('poster', noVideo);
    },
    onError() {
      console.log('error', this.videoName);
      clearTimeout(this.timeout);
      this.videoElement?.setAttribute('poster', noVideo);
    },
    checkIfVideoExpired() {
      this.videoElement = document.getElementById(this.videoUrl);
      // if (this.index === 0) {
      // const isExpired = isLinkExpired(this.videoUrl);

      // if (isExpired) {
      // this.updateLink();
      // }
      // }
    },
  },
};
</script>
<style scoped>
.video-border {
  border: 1px solid black;
}

.video-play {
  width: 140px;
  border-radius: 5px;
  height: 124px;
}
</style>
