<template>
  <div>
    <!-- Button trigger modal -->
    <button
      hidden
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      :data-target="'#' + id"
      ref="openButton"
    />

    <!-- Modal -->
    <div
      class="modal fade"
      :id="id"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :class="{ 'modal-dialog-scrollable': taskJson }"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header" style="height:50px">
            <!-- ===================================================== -->
            <slot name="title"></slot>
            <!-- ===================================================== -->
            <button
              type="button"
              class="close d-sm-block d-block d-md-none"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- ================================================= -->
            <slot name="body"></slot>
            <!-- ================================================= -->
          </div>
          <div class="modal-footer">
            <!-- ============================================= -->
            <slot name="footer"></slot>
            <!-- ============================================= -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import DropdownComponent from '../../../shared/Components/Dropdown.vue';
// import SpinnerComponent from '../../../shared/Components/Spinner.vue';

export default {
  //   components: {
  //     DropdownComponent,
  //     SpinnerComponent,
  //   },
  // props: ['title', 'organizations', 'selectedOrg', 'loading'],
  props: {
    id: {},
    taskJson: { default: false },
  },
  emits: ['onClose'],
  mounted() {
    var self = this;
    $('#' + this.id).on('hidden.bs.modal', function() {
      console.log('close modal');
      self.$emit('onClose');
    });
  },
  methods: {
    toggleModal() {
      this.$refs.openButton.click();
    },
  },
};
</script>
<style scoped>
.modal {
  z-index: 10000;
}

.modal-body {
  height: 100% !important;
}
.modal-content {
  margin-left: 15px !important;
  height: 100% !important;
}
</style>
