<template>
  <a-descriptions
    title="Annotation Instructions"
    :colon="false"
    size="small"
    v-if="!isVisualize"
    id="trim-video-instruction"
  >
    <a-descriptions-item label="1." span="3">
      Click on a Trim Video.
    </a-descriptions-item>
    <a-descriptions-item label="2." span="3">
      Adjust Slider to the Frame/Time you want to start trimming and press
      space.
    </a-descriptions-item>
    <a-descriptions-item label="3." span="3">
      Move the slider to the Fram/Time until desired end frame and press space
      again.
    </a-descriptions-item>
    <a-descriptions-item label="4." span="3">
      Repeat steps 1-3 to select more video to crop.
    </a-descriptions-item>
    <a-descriptions-item label="5." span="3">
      When done with cropping, press Trim Video button to start trimming
      process.
    </a-descriptions-item>
  </a-descriptions>
</template>
<script></script>
