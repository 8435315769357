<template>
  <modal-component :show="show">
    <div class="modal-header">
      <strong class="modal-title">{{ selectedVideo?.fileName }}</strong>
      <button type="button" class="close" @click="$emit('close')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body d-flex justify-content-center">
      <video
        :controls="true"
        class="w-100"
        style="border-radius:5px;"
        :src="selectedVideo?.localURL || selectedVideo?.fileURL"
      ></video>
    </div>
    <hr />
    <div class="modal-footer row">
      <div class="col col-xs-3video-info m-0 pl-5">
        <p>
          <strong>Name: </strong>{{ selectedVideo?.fileName }}
          <br />
          <strong>Resolution: </strong>{{ selectedVideo?.resolution }}
          <br />
          <strong>Duration: </strong>{{ selectedVideo?.duration }}
          <br />
          <strong>FPS: </strong>{{ selectedVideo?.fps }}
          <br />
          <strong>Labelled: </strong>{{ selectedVideo?.labelled }}
          <br />
        </p>
      </div>
    </div>
  </modal-component>
</template>
<script>
import ModalComponent from '../../../shared/Components/Modal.vue';

export default {
  components: {
    ModalComponent,
  },
  props: ['show', 'selectedVideo'],
  emits: ['close'],
};
</script>
<style>
.modal-footer {
  border: none;
}
</style>
