<template>
  <div>
    <!-- Button trigger modal -->
    <button
      hidden
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      :data-target="`#${title}`"
      ref="closeButton"
    />

    <!-- Modal -->
    <div
      class="modal fade"
      :id="title"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalTitle"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        :class="{ 'modal-lg': fullpage, 'modal-md': !fullpage }"
      >
        <div class="modal-content">
          <div
            class="modal-header d-flex align-items-center"
            style="height: 50px"
          >
            <slot name="header"></slot>
          </div>
          <div class="modal-body" :id="`modal-body${title}`">
            <slot></slot>
          </div>
          <!-- <div class="modal-footer" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { title: {}, fullpage: { default: true } },
  emits: ['onClose'],
  mounted() {
    var self = this;
    $(`#${this.title}`).on('hidden.bs.modal', function() {
      console.log('close modal', this.title);
      self.$emit('onClose');
    });
  },
  methods: {
    toggleModal() {
      this.$refs.closeButton.click();
    },
  },
};
</script>
<style scoped>
.modal-lg {
  max-width: 100% !important;
}

.modal-md {
  max-width: 70% !important;
}

.modal {
  z-index: 10000;
}

.modal-body {
  overflow-y: scroll;
  padding: 0 !important;
}
.modal-content {
  height: 90vh;
  margin-left: 10px !important;
}
</style>
