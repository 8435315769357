<template>
  <div v-if="show">
    <transition name="modal">
      <div class="modal-mask w-100">
        <div class="modal-wrapper w-100">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: ['show'],
  data: function() {
    return {
      showModal: true,
      url:
        'https://devtestorg121-videos.s3.amazonaws.com/c8%3Ab2%3A9b%3Aef%3Aed%3A4a/141/Processed/2021-06-10%2018%3A25%3A28.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYWFGI2RLP5NPN2O3%2F20210610%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20210610T132956Z&X-Amz-Expires=518400&X-Amz-SignedHeaders=host&X-Amz-Signature=f4e998fefb446ae044cc372fb9a6dbe9b05c24d10ddecb147279c793f2056650',
    };
  },
};
</script>
<style>
.modal-mask {
  max-width: 1000px;
  margin: 0;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  /* right: 0; */
  /* height: 100vh; */
  display: flex;
  position: fixed;
  z-index: 10001;
  /*
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease; */
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-content {
  /* height: 100%; */
  height: 95vh;
}

.modal-body {
  height: 50vh;
}

.video-info {
  flex-direction: column;
  align-items: flex-start;
}
</style>
