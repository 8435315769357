export default function(duration) {
  // 00:10
  const units = {
    0: 1,
    1: 60,
    2: 3600,
  };
  const time = duration?.split(':');
  let dur = 0;
  let timeUnit = 0;
  while (time?.length > 0) {
    dur += Number(time.pop()) * units[timeUnit];
    timeUnit++;
  }
  return dur;
}
